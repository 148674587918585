import { getAnalytics, isSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD47aPFPu4THy5YJnmxxf5L3iR3biO1h_c",
  authDomain: "aishirtz.firebaseapp.com",
  projectId: "aishirtz",
  storageBucket: "aishirtz.appspot.com",
  messagingSenderId: "626645147496",
  appId: "1:626645147496:web:933b352f636f3991f580dd",
};

const app = initializeApp(firebaseConfig);

if (typeof window !== "undefined") {
  isSupported().then((supported) => {
    if (supported) {
      const analytics = getAnalytics(app);
      console.log("Firebase Analytics initialized", { analytics });
    } else {
      console.warn("Firebase Analytics is not supported in this environment.");
    }
  });
}

export default app;
