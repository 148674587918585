import * as React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as sendPasswordReset,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  connectAuthEmulator,
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  doc,
  onSnapshot,
  connectFirestoreEmulator,
} from "firebase/firestore";

import firebase from "../firebase";

const auth = getAuth(firebase);
const database = getFirestore(firebase);

/* if (window?.location?.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(database, "localhost", 8080);
} */

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let databaseUnsubscribe;

    const authUnsubscribe = onAuthStateChanged(auth, async (userFromAuth) => {
      if (userFromAuth) {
        setUserLoggedIn(true);

        const userFromDatabase = await getUser(userFromAuth.uid);

        setUser({
          id: userFromAuth.uid,
          name: userFromAuth.displayName,
          photo: userFromAuth.photoURL,
          email: userFromAuth.email,
          email_verified: userFromAuth.emailVerified,
          ...(userFromDatabase || {}),
        });

        databaseUnsubscribe = listenUserChanges(
          userFromAuth.uid,
          (userSnap) => {
            const userData = userSnap.data();

            setUser({
              id: userFromAuth.uid,
              name: userFromAuth.displayName,
              photo: userFromAuth.photoURL,
              email: userFromAuth.email,
              email_verified: userFromAuth.emailVerified,
              ...(userData || {}),
            });
          }
        );
      } else if (userLoggedIn) {
        setUser(null);
      }

      setLoadingUser(false);
    });

    return () => {
      authUnsubscribe();
      databaseUnsubscribe && databaseUnsubscribe();
    };
  }, []);

  const getUserReference = (userId) => {
    return doc(database, "users", userId);
  };

  const getUser = async (userId) => {
    const userSnap = await getDoc(getUserReference(userId));
    return userSnap.data();
  };

  const listenUserChanges = (userId, listener) => {
    return onSnapshot(getUserReference(userId), listener);
  };

  const logInWithEmailAndPassword = async (email, password) => {
    try {
      const userRecord = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      return userRecord;
    } catch (error) {
      console.error(error);

      return { error: error.message };
    }
  };

  const registerWithEmailAndPassword = async (email, password) => {
    try {
      const userRecord = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await sendEmailVerification(userRecord.user);

      return userRecord;
    } catch (error) {
      console.error(error);

      return { error: error.message };
    }
  };

  const loginWithGoogleAccount = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userRecord = await signInWithPopup(auth, provider);

      return userRecord;
    } catch (error) {
      console.error(error);

      return { error: error.message };
    }
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      await sendPasswordReset(auth, email);

      return { success: true };
    } catch (error) {
      console.error(error);

      return { error: error.message };
    }
  };

  const logout = () => {
    signOut(auth);
  };

  const value = useMemo(
    () => ({
      user,
      loadingUser,
      userLoggedIn,
      logInWithEmailAndPassword,
      registerWithEmailAndPassword,
      loginWithGoogleAccount,
      sendPasswordResetEmail,
      logout,
    }),
    [user, loadingUser, userLoggedIn]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
