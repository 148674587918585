exports.components = {
  "component---src-components-print-theme-jsx": () => import("./../../../src/components/PrintTheme.jsx" /* webpackChunkName: "component---src-components-print-theme-jsx" */),
  "component---src-components-shirt-generators-page-jsx": () => import("./../../../src/components/ShirtGeneratorsPage.jsx" /* webpackChunkName: "component---src-components-shirt-generators-page-jsx" */),
  "component---src-components-shirt-page-jsx": () => import("./../../../src/components/ShirtPage.jsx" /* webpackChunkName: "component---src-components-shirt-page-jsx" */),
  "component---src-components-tag-page-jsx": () => import("./../../../src/components/TagPage.jsx" /* webpackChunkName: "component---src-components-tag-page-jsx" */),
  "component---src-components-tags-page-jsx": () => import("./../../../src/components/TagsPage.jsx" /* webpackChunkName: "component---src-components-tags-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cancelled-jsx": () => import("./../../../src/pages/cancelled.jsx" /* webpackChunkName: "component---src-pages-cancelled-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

